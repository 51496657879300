import request from '@/utils/request'

export const login = (data: any) =>
  request({
    url: '/patients/authenticate',
    method: 'post',
    data
  })

export const register = (data: any) =>
  request({
    url: '/patients/register',
    method: 'put',
    data
  })

export const passwordreset = (patientId: string, data: any) =>
  request({
    url: '/patients/' + patientId +'/passwordreset' ,
    method: 'put',
    data
  })

export const passwordlost = (data: any) =>
  request({
    url: '/patients/passwordlost',
    method: 'put',
    data
  })

export const refreshtoken = () =>
  request({
    url: '/patients/refresh',
    method: 'get'
  })

export const logout = () =>
  request({
    url: '/patients/logout',
    method: 'post'
  })
