












import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { DeviceType, AppModule } from '@/store/modules/app'
import AppMain from './AppMain.vue'
import Navbar from './Navbar.vue'
import ResizeMixin from './mixin/resize'

@Component({
  name: 'Layout',
  components: {
    AppMain,
    Navbar
  }
})
export default class extends mixins(ResizeMixin) {
  get classObj() {
    return {
      mobile: this.device === DeviceType.Mobile
    }
  }
  get loading()
  {
    return AppModule.loading;
  }
}
