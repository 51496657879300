import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/permission'

// Bootstrap
import { BootstrapVue } from 'bootstrap-vue';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faKey, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUser, faKey, faEye, faEyeSlash)
Vue.component('font-awesome-icon', FontAwesomeIcon)
import './scss/verbis-autoeval.scss'
Vue.use(BootstrapVue);

// Message depuis element-ui
import { Message, MessageBox } from 'element-ui';
Vue.component(Message.name, Message);
Vue.component(MessageBox.name, MessageBox);

// Inactivité
import IdleVue from 'idle-vue'
const idleOptions = {
  eventEmitter: new Vue(),
  store,
  idleTime: 1000 * 60 * 15, // 15 minutes
  startAtIdle: false,
  events: ['mousemove', 'keydown', 'mousedown', 'touchstart', "scroll"]
}
// Ajout des hooks onIdle et onActive
import Component from 'vue-class-component';
Component.registerHooks([
    'onIdle',
    'onActive',
]);
Vue.use(IdleVue, idleOptions);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
