import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'

export enum DeviceType {
  Mobile,
  Desktop,
}

/* eslint-disable @typescript-eslint/interface-name-prefix */
export interface IAppState {
  device: DeviceType
  loading: boolean
}

@Module({ dynamic: true, store, name: 'app', preserveState: localStorage.getItem('vuex') !== null  })
class App extends VuexModule implements IAppState {
  public device = DeviceType.Desktop
  public loading = false

  @Mutation
  private TOGGLE_DEVICE(device: DeviceType) {
    this.device = device
  }

  @Action
  public ToggleDevice(device: DeviceType) {
    this.TOGGLE_DEVICE(device)
  }

  @Mutation
  private TOGGLE_LOADING(loading: boolean) {
    this.loading = loading
  }

  @Action
  public ToggleLoading(loading: boolean) {
    this.TOGGLE_LOADING(loading)
  }
}

export const AppModule = getModule(App)
