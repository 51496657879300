
























import { Component, Vue } from "vue-property-decorator";
import { bus } from "@/eventBus";

@Component
export default class Footer extends Vue {
  //#region Data
  private busy = false;
  private visibilityButton = false;
  //#endregion

  //#region Hooks
  created() {
    // Brancher l'évenement submit du bus
    bus.$on("loading-submit", () => {
      this.busy = !this.busy;
    });
    // Brancher l'évenement sur les erreurs de chargement
    bus.$on("visibility-footer", (visibility: boolean) => {
      this.visibilityButton = visibility;
    });
  }

  beforeDestroy() {
    // Débrancher les évenements
    bus.$off("loading-submit");
    bus.$off("visibility-footer");
  }
  //#endregion

  //#region Methods
  private async onClick() {
    // Sauvegarde
    bus.$emit("submit-form");
  }
  //#endregion
}
