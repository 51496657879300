import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import LayoutQuestionaire from '@/layout/LayoutQuestionnaire.vue'
import LayoutBase from '@/layout/LayoutBase.vue'

Vue.use(VueRouter);

export default new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: () => import('@/views/Login.vue'),
      meta: {
        title: 'Connexion',
        hidden: true
      }
    },
    {
      path: '/register',
      component: () => import('@/views/Register.vue'),
      meta: {
        title: 'Inscription',
        hidden: true
      }
    },
    {
      path: '/resetpassword',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        title: 'Ré-initialiser le mot de passe',
        hidden: true
      }
    },
    {
      path: '/lostpassword',
      component: () => import('@/views/LostPassword.vue'),
      meta: {
        title: 'Mot de passe oublié',
        hidden: true
      }
    },
    {
      path: '/',
      component: LayoutBase,
      redirect: '/accueil',
      children: [
        {
          path: 'accueil',
          component: () => import('@/views/Accueil.vue'),
          meta: {
            title: 'Accueil',
          }
        }
      ]
    },
    {
      path: '/questionnaire*',
      component: LayoutQuestionaire,
      children: [
        {
          path: 'autoevaluationsalaries/:id',
          component: () => import('@/views/questionnaires/AutoEvaluationSalaries.vue'),
          meta: {
            title: 'Auto-évaluation salariés',
            hidden: true
          },
        },
        {
          path: 'autoevaluationliberaux/:id',
          component: () => import('@/views/questionnaires/AutoEvaluationLiberaux.vue'),
          meta: {
            title: 'Auto-évaluation libéraux',
            hidden: true
          }
        }
      ]
    }
  ]
})