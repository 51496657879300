

















import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";

@Component
export default class Navbar extends Vue {
  get userName() {
    return UserModule.name;
  }

  private async logout() {
    await UserModule.LogOut(true)
  }
}
