/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable prefer-const */
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import { login, logout } from '@/api/patients'
import { removeToken } from '@/utils/cookies'
import store from '@/store'
import router from '@/router'

export interface IUserState {
  connected: boolean;
  name: string;
}

@Module({ dynamic: true, store, name: 'user', preserveState: localStorage.getItem('vuex') !== null })
class User extends VuexModule implements IUserState {
  public connected = false;
  public name = '';

  @Mutation
  private SET_TOKEN(connected: boolean) {
    this.connected = connected;
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name;
  }

  @Action
  public async Login(userInfo: { username: string, password: string }) {
    let { username, password } = userInfo;
    username = username.trim();
    const { data } = await login({ username, password });
  }

  @Action
  public ResetToken() {
    removeToken();
    this.SET_TOKEN(false);
  }

  @Action
  public InitToken(accessToken: string) {
    this.SET_TOKEN(true);
    // On parse le payload pour récupérer le nom + les roles de l'utilisateur
    const payload = JSON.parse(window.atob(accessToken.split('.')[1]));
    this.SET_NAME(payload.email);
  }

  @Action
  public async LogOut(redirect: boolean) {
    await logout();
    removeToken();
    this.SET_TOKEN(false);
    if (redirect)
      router.push(`/login`);
  }
}

export const UserModule = getModule(User)
